export const MenuItems = [
  // {
  //   title: "Digital Art",
  //   url: "/digital-art",
  //   cName: "nav-links",
  // },
  {
    title: "Video Art",
    url: "/video-art",
    cName: "nav-links",
  },
  {
    title: "About",
    url: "/about",
    cName: "nav-links",
  },
  {
    title: "Contact",
    url: "/contact",
    cName: "nav-links",
  },
];
